<script lang="ts" setup>
import { useDisplay } from "vuetify";
const { inAside } = defineProps({
    icon: { type: String, default: "" },
    title: { type: String, default: "" },
    text: { type: String, default: "" },
    url: { type: String, default: "" },
    color: { type: String, default: "" },
    target: { type: String, default: "" },
    inAside: Boolean
});

const { sm, md, lg, xlAndUp } = useDisplay();

const iconSize = computed(() => {
    if (sm) return "2rem";
    if (md) return ".7rem";
    if (lg) return ".7rem";
    if (xlAndUp) return ".7rem";
    return ".7rem";
});
</script>
<template>
    <VRow no-gutters :style="`--color: var(--v-theme-${color})`">
        <VCol cols="auto">
            <div
                :class="`border-${color}`"
                class="border border-opacity-100 border-md rounded-lg d-flex align-center align-md-start align-lg-center justify-center pa-1 pa-sm-4 mr-3 mr-md-4 mr-lg-7"
            >
                <VIcon :icon="`$${icon}`" :color="color" :size="iconSize" />
            </div>
        </VCol>
        <VCol cols>
            <VRow no-gutters align-content="center" class="fill-height">
                <VCol cols="12">
                    <span :class="['d-none d-sm-block text-body-1 text-lg-h6 text-xl-h5 font-weight-medium', { 'd-block': inAside }]">{{ title }}</span>
                </VCol>
                <VCol cols="12">
                    <NuxtLink :to="url" :target="target"><span v-html="text"></span></NuxtLink>
                </VCol>
            </VRow>
        </VCol>
    </VRow>
</template>
